const styles = {
  container:
    'flex items-center justify-center !text-white font-display h-full grow',
  content: 'flex flex-col items-center gap-y-2',

  head: 'flex flex-col items-center',
  foot: '',

  title:
    'text-8xl sm:text-[8rem] font-thin text-center pointer-events-none text-white',
  description: 'mt-4 text-4xl tracking-display',

  button: {
    container: 'flex flex-row items-center gap-x-4',
    label: 'text-center hover:underline font-bold',
  },
};

export default styles;
